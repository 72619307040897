var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "center-col": "", "pl-2": "", "pr-2": "", "app-index": "" } },
    [
      _vm.loading ? _c("Loading", { attrs: { text: _vm.text } }) : _vm._e(),
      _vm._v(" "),
      _vm.isProducts
        ? _c(
            "el-button",
            {
              attrs: { "mt-1i": "", "go-shop": "" },
              on: { click: _vm.goProducts },
            },
            [_vm._v("商品の購入はこちら")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Footer", { on: { cartToggle: _vm.cartToggle } }),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "現在のカートの中",
            visible: _vm.drawer,
            direction: _vm.direction,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [_c("Cart")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }